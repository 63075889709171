import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'
import { Card, Box, Label, Input, Button, Message, Spinner } from 'theme-ui'


const MauticForm = ({ handleSubmit, submitting, success, formId }) => {

  const [url, setUrl] = useState(0);

  useEffect(() => {
    setUrl((typeof window !== undefined) ? window.location.href : '');
  },[]);

  const data = useStaticQuery(mauticFormQuery);

  // get the mautic form information
  const { edges } = (data && data.allMauticForm) || {}
  
  if (!edges) return
  
  const form = edges[0].node;
  // const formId = form.id;
  const formName = form.name;
  const formFields = form.formFields;
  
  const fields = formFields.map(ff => {

    if (ff.alias !== 'submit') {
      return (
        <Box variant='forms.row'>
            <Label htmlFor={ff.alias}>{ff.label}</Label>
            <Input id={ff.alias} name={`${ff.alias}`} placeholder={ff.properties.placeholder} required={ff.isRequired} />
        </Box>
      );
    }

    if (ff.alias === 'submit') {
      return (
        <Box variant='forms.row'>
          <Box variant='forms.column'>
          <Button
            name="submit"
            variant={success || submitting ? 'disabled' : 'primary'}
            disabled={success || submitting}
            type='submit'
            required
          >
            {ff.label} {submitting && <Spinner size='20' />}
          </Button>
          </Box>
        </Box>
      );
    }
  });


  return fields !== null ? (
    <div>
    <Card variant='paper'>
      <h2>Sign up to receive news and updates</h2>
      <form onSubmit={handleSubmit} className="w-full flex">
        <input type="hidden" name="formId" value={formId} />
        <input type="hidden" name="return" value={url} />
        <input type="hidden" name="formName" value={formName} />
        <input type="hidden" name="messenger" value="1" />
        {fields}
      </form>
    </Card>
    </div>
  ) : null;
}

const mauticFormQuery = graphql`
    query MauticNewsletterFormQuery {
      allMauticForm(filter: {id: {eq:"2"}}) {
        edges {
          node {
            id
            formFields {
              inputAttributes
              id
              alias
              label
              isRequired
              properties {
                placeholder
              }
              leadField
            }
            formType
            name
            internal {
              content
              description
              ignoreType
              mediaType
            }
            alias
          }
        }
      }
    }
    `

MauticForm.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  success: PropTypes.bool
}

export default MauticForm;
