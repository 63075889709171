import { useState, useEffect, useCallback } from 'react'
var fetch = require("node-fetch");
const FormData = require('form-data');
// const MauticConnector = require('node-mautic');
// const mauticConnector = new MauticConnector({
//   apiUrl: 'https://mautic.swingbotpro.com/mautic',
//   username: 'jptrops',
//   password: 'chmc5672' ,
//   timeoutInSeconds: 5
// });

const useForm = () => {
  const [submitting, setSubmitting] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [values, setValues] = useState({})
  const [success, setSuccess] = useState(false)

  const handleSubmit = e => {
    e.preventDefault()
    setSubmitting(true)
    const data = new FormData(e.target)
    const newValues = {}
    for (var pair of data.entries()) {
      newValues[pair[0]] = pair[1]
    }
    setValues(newValues)
    setSubmitted(true)
  }

  const sendValues = useCallback(() => {

    const formData = new FormData();

    Object.keys(values).forEach(v => {
      formData.append(`mauticform[${v}]`, values[v]);
    })

    // formData.append('mauticform[email_address]', values['email_address'])
    // formData.append('mauticform[formId]', values['formId']);
    // formData.append('mauticform[return]', values['return']);
    // formData.append('mauticform[formName]', values['formName']);


    formData.append('mauticform[messenger]', 1);
    
    fetch(`https://mautic.swingbotpro.com/mautic/form/submit?formId=${values['formId']}`, {
      method: 'post',
      body: formData,
      headers: { 'X-Requested-With': 'XMLHttpRequest' }
    }).then(result => {
      setSuccess(true);
      setSubmitting(false);
    }).catch(e => {
      setSuccess(false);
      setSubmitting(false);
    }); // end

  }, [values])

  useEffect(() => {
    if (submitted) {
      sendValues()
    }
  }, [submitted, sendValues])

  return {
    handleSubmit,
    submitting,
    submitted,
    success
  }
}

export default useForm