// /site/src/@elegantstack/flow-ui-theme/theme/index.js
import theme from "@elegantstack/flow-ui-theme/src/theme/index"
//Using tailwind preset colors
import colorPreset from "@elegantstack/flow-ui-theme/src/theme/color-preset"
export default {
  ...theme,
  colors: {
    ...theme.colors,
    alphaLighter: colorPreset.green[100],
    alphaLight: colorPreset.green[300],
    alpha: colorPreset.green[600],
    alphaDark: colorPreset.green[800],
    alphaDarker: colorPreset.green[900],
  },
}